import Home from './component/Home';
import './App.css';
import '../src/assests/styles/Home.scss'
function App() {
  return (
    <div className="App">
    <Home/>
    </div>
  );
}

export default App;
