import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { toWords } from 'number-to-words';
import ReactDOMServer from 'react-dom/server';


// New component for print view
const PrintView = ({ totalAmount, countryCode, denominations, quantities }) => {
  return (
      <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: '0 auto', padding: '20px', border: '1px solid black' }}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Cash Calculator Summary</h2>
          <h3>Total Cash = {totalAmount.toFixed(2)}</h3>
          <hr style={{ border: 'none', borderTop: '1px solid black', margin: '10px 0' }} />
          
          <h4 style={{ marginBottom: '5px' }}>Notes</h4>
          <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px' }}>
              <thead>
                  <tr>
                      <th style={{ borderBottom: '1px solid black', padding: '8px', textAlign: 'left' }}>Value</th>
                      <th style={{ borderBottom: '1px solid black', padding: '8px', textAlign: 'center' }}>Quantity</th>
                      <th style={{ borderBottom: '1px solid black', padding: '8px', textAlign: 'right' }}>Total</th>
                  </tr>
              </thead>
              <tbody>
                  {denominations.map((denomination) => {
                      const quantity = parseInt(quantities[denomination.id] || '0', 10);
                      const value = parseInt(denomination.noteDenomination.replace(/[^0-9]/g, ''), 10);
                      const total = value * quantity;
                      if (quantity > 0) {
                          return (
                              <tr key={denomination.id}>
                                  <td style={{ padding: '8px', textAlign: 'left' }}>{value}</td>
                                  <td style={{ padding: '8px', textAlign: 'center' }}>x {quantity}</td>
                                  <td style={{ padding: '8px', textAlign: 'right' }}>{total}</td>
                              </tr>
                          );
                      }
                      return null;
                  })}
                  <tr>
                      <td style={{ padding: '8px', fontWeight: 'bold', textAlign: 'left' }}>Total</td>
                      <td style={{ padding: '8px', textAlign: 'center' }}>{denominations.reduce((sum, d) => sum + parseInt(quantities[d.id] || '0', 10), 0)}</td>
                      <td style={{ padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>{totalAmount.toFixed(2)}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  );
};


function Home() {
    const [currencies, setCurrencies] = useState([]);
    const [countryCode, setCountryCode] = useState('1');

    const [denominations, setDenominations] = useState([]);
    const [quantities, setQuantities] = useState({});
    // const [coins, setCoins] = useState([]);
    const [showNotes, setShowNotes] = useState(true); // State to show notes
    // const [showCoins, setShowCoins] = useState(false); 
    
    useEffect(() => {
        fetch('https://mycashcalculator.com/mycashcalulator.php.api/get-country-api.php')
            .then((response) => response.json())
            .then((data) => setCurrencies(data.country))
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    useEffect(() => {
        if (countryCode) {
            fetch(`https://mycashcalculator.com/mycashcalulator.php.api/get-currency-api.php?countryId=${countryCode}`)
                .then((response) => response.json())
                .then((data) => {
                    setDenominations(data.note);
                    // setCoins(data.coins); // Add this line to set coins
                })
                .catch((error) => console.error('Error fetching denominations:', error));
        } else {
            setDenominations([]);
            // setCoins([]); // Clear coins when no country code is selected
            setQuantities({});
        }
    }, [countryCode]);

    const handleSelectChange = (event) => setCountryCode(event.target.value);

    const handleQuantityChange = (denominationId, value) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [denominationId]: value,
        }));
    };

    const calculateTotal = () => {
        const totalNotes = denominations.reduce((total, denomination) => {
            const quantity = parseInt(quantities[denomination.id] || '0', 10);
            const value = parseInt(denomination.noteDenomination.replace(/[^0-9]/g, ''), 10);
            return total + value * quantity;
        }, 0);

        // const totalCoins = coins.reduce((total, coin) => {
        //     const quantity = parseInt(quantities[coin._id] || '0', 10);
        //     const value = parseInt(coin.coinDenomination.replace(/[^0-9]/g, ''), 10);
        //     return total + value * quantity;
        // }, 0);

        return totalNotes ;
    };

    const calculateBreakdown = () => {
        const notesBreakdown = denominations.map((denomination) => {
            const quantity = parseInt(quantities[denomination.id] || '0', 10);
            const value = parseInt(denomination.noteDenomination.replace(/[^0-9]/g, ''), 10);
            const total = value * quantity;
            return { value, quantity, total };
        });

        return [...notesBreakdown];
    };

    const totalNotesTotal = denominations.reduce((sum, denomination) => {
        const quantity = parseInt(quantities[denomination.id] || '0', 10);
        const value = parseInt(denomination.noteDenomination.replace(/[^0-9]/g, ''), 10);
        return sum + (quantity > 0 ? value * quantity : 0); // Only sum if quantity is greater than 0
    }, 0);


    const totalAmount = calculateTotal();
    const totalInWords = toWords(totalAmount);

    // Calculate total count and value for notes and coins
    const totalNotesCount = denominations.reduce((sum, denomination) => {
        return sum + parseInt(quantities[denomination.id] || '0', 10);
    }, 0);

   

    const showNotesTotal = totalNotesCount > 0;
    // const showCoinsTotal = totalCoinsCount > 0;

    const handlePrint = () => {
        const printContent = ReactDOMServer.renderToString(
          <PrintView
            totalAmount={calculateTotal()}
            countryCode={countryCode}
            denominations={denominations}
            // coins={coins}
            quantities={quantities}
          />
        );
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <title>Money Calculator Summary</title>
            </head>
            <body>
              ${printContent}
              <script>
                window.onload = function() { window.print(); window.close(); }
              </script>
            </body>
          </html>
        `);
        printWindow.document.close();
      };
    return (
        <>

            <Header />
            <div className="home-container">
                <div className="calculator-container">
                    <h1>Cash Calculator</h1>
                    <div className="calci">
                        <div className="currency-select">
                            <label className="label">Select a Currency : </label>
                            <select className="sl" value={countryCode} onChange={handleSelectChange}>
                                <option value="">Select a Currency</option>
                                { console.log(currencies, 'hhhhhhhfffffff currencies')}
                                {currencies.map((currency) => (
                                    <option key={currency.id} value={currency.id}>
                                        {currency.countryName}
                                    </option>
                                ))}
                            </select>
                        </div>


                        {/* Conditionally show notes */}
                        {showNotes && denominations.length > 0 && (
                            <div className="denominations-table">
                                <h3 className='headingCl'>Notes How Many?</h3>
                                <table className="calculations">
                                    <thead>
                                        <tr>
                                            <th>Currency</th>
                                            <th>Value</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {denominations.map((denomination) => {
                                            const quantity = quantities[denomination.id] || 0;
                                            const value = parseInt(denomination.noteDenomination.replace(/[^0-9]/g, ''), 10);
                                            const total = value * quantity;
                                            return (
                                                <tr key={denomination.id}>
                                                    <td>{denomination.noteDenomination}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="small-input"
                                                            value={quantity}
                                                            onChange={(e) => handleQuantityChange(denomination.id, e.target.value)}
                                                            placeholder={`Enter quantity for ${denomination.noteDenomination}`}
                                                              min="0"
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    !/^[0-9\b]+$/.test(e.key) &&
                                                                    e.key !== 'Backspace' &&
                                                                    e.key !== 'Delete' &&
                                                                    !e.key.includes('Arrow')
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onFocus={(e) => {
                                                              if (e.target.value === '0') {
                                                                e.target.value = '';
                                                              }
                                                            }}
                                                            onBlur={(e) => {
                                                              if (e.target.value === '') {
                                                                e.target.value = '0';
                                                              }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{isNaN(total) ? 0 : total}</td>
                                                </tr>
                                            );
                                        })}
                                             {showNotesTotal && (
                                            <tr>
                                                <td>Total</td>
                                                <td>{totalNotesCount}</td>
                                                <td>{totalNotesTotal}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}

                       
                        <div className='total'>
                            {/* <p>Total Calculated Money = {totalAmount} ({countryCode})</p> */}
                            <p>Total Calculated Money = {totalAmount} </p>
                            <i>In words: {totalInWords}</i>
                       
                        </div>
                        <button onClick={handlePrint} className="print-button">Print Summary</button>
                        <hr />
                    </div>
                </div>
                <div className="tips-container">

                    <p>The Money Calculator is an essential tool for accurately determining the total value of your cash by adding together different denominations of banknotes. Whether you're dealing with loose change, cash registers, or large amounts of cash, this calculator simplifies the task by using precise terminology like "banknotes" or "notes" for paper currency.</p>
                    <p>This versatile money calculator can be used in various situations where you need to quickly and reliably count and sum up cash. It serves multiple purposes, such as:</p>
                    <ul>
                        <li>Money Counter: Calculates the total sum of mixed currency quickly.</li>
                        <li>Cash Counter: Provides a total for cash amounts, useful for businesses and personal use.</li>
                        <li>Cash Register Counter: Ideal for retail environments to tally daily cash intake.</li>
                        <li>Till Counter: Ensures cash drawers are balanced accurately at the end of shifts.</li>
                    </ul>

                    <p>The currencies supported include:</p>
                    <ul>
                        <li>Indian Rupee (INR)</li>
                        <li>United States Dollar (USD)</li>
                        <li>Japanese Yen (JPY)</li>
                        {/* Add more tips as needed */}
                    </ul>

                    <h2>How to Calculate Total Amount of Money</h2>
                    <p>To add up the total amount of cash and coins you have, first sort each note and coin according to value. Make a separate pile for each denomination and then count how many of each bill or coin value you have.

                        For each bill and coin value, multiply the number you have by the face value. For example, if you have 4 of $10 note multiply 4 × 10 to get $40. If you have 3 of the $5 note multiply 3 × 5 to get $15.

                        Add all of the totals together to calculate the total sum of money.</p>


                    <h2>Steps to Calculate Money</h2>
                    <ul>
                        <li> 1: Sort cash and coins so that each denomination is in its own stack</li>
                        <li>2: Make a separate count of how many bills or coins are in each stack</li>
                        <li>3: For each stack, multiply the face value by the number of bills or coins in that stack</li>
                        <li>4: Add the totals of all stacks together. This is the sum total of the money.</li>
                        {/* Add more tips as needed */}
                    </ul>
                </div>


            </div>
            <Footer />
        </>
    );
}

export default Home;
