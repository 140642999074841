import React from 'react';
import logo from '../assests/images/logo2.jpeg'
import playStore from '../assests/images/playstorelight.png'
import '../assests/styles/Header.scss'; 
function Header() {
    return (
        <header className="header">
            <div className="logo-container">
                <img src={logo} alt="Logo"  className="logo" />
              
            </div>
            <div className="logo-container">
             <a href='https://play.google.com/store/games?hl=en'>   <img src={playStore} alt="Logo"  className="playStore" /></a>
              
            </div>
        </header>
    );
}

export default Header;
